import { getPrettyName } from "@utils/user-utils";
import { SampleMember } from "@custom-types/teams-types";
import { MemberAvatars } from "@components/common/member/member-avatars";

interface Props {
  /** List of member of a team */
  members: SampleMember[];
}

/**
 * Shows avatars of the team members of a team
 */
export function TeamsMembersColumn({ members }: Props): JSX.Element {
  // Show the team members sorted by name
  const sortedTeamMembers = members
    .map((member) => member.userResponse)
    .sort((a, b) => getPrettyName(a).localeCompare(getPrettyName(b)));

  return <MemberAvatars members={sortedTeamMembers} maxMemberAvatars={4} />;
}
