import { SphereTooltip } from "@components/common/sphere-tooltip";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import DeleteIcon from "@assets/icons/new/delete_32px.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { Box, Link } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { Team } from "@custom-types/teams-types";
import { useTeamUtils } from "@hooks/use-team-utils";

interface Props {
  /** The team data */
  team: Team;
}

/** Render team table action elements */
export function TeamsTableAction({ team }: Props): JSX.Element {
  const { openTeamDetails } = useTeamUtils();

  async function onViewDetailsClick(): Promise<void> {
    openTeamDetails({
      teamId: team.id,
      // TODO: Replace actual number of members, sampleMembers is not the total numbers of the team
      // https://faro01.atlassian.net/browse/ST-2379
      numberOfMembers: team.sampleMembers.length,
    });
  }

  return (
    <Box
      className={SHOW_ON_HOVER_CLASS}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        // This will be overridden by parent component using SHOW_ON_HOVER_CLASS
        visibility: "hidden",
      }}
    >
      {/* View details link */}
      <Link
        onClick={onViewDetailsClick}
        underline="always"
        sx={{
          marginLeft: "10px",
          color: sphereColors.blue500,
          textDecorationColor: sphereColors.blue500,
          cursor: "pointer",
        }}
      >
        View details
      </Link>

      <SphereActionDivider />

      {/* Delete group button */}
      <SphereTooltip title={"Delete group"}>
        <FaroIconButton component={DeleteIcon} iconSize="18px" />
      </SphereTooltip>
    </Box>
  );
}
