/** Used to calculate file size in byte, megaByte and gigaByte */
export const FILE_SIZE_MULTIPLIER = 1024;

/**
 * Check the extension of the file if it's valid or not
 *
 * @param {file} file The file to check
 * @returns true if the file has a supported format
 */
export function isValidFileExtension(
  file: File,
  validExtensions: string[]
): boolean {
  const extension = getFileExtension(file.name)?.toLowerCase();
  return !!extension && validExtensions.includes(extension);
}

/** Returns mega bytes after converting from bytes */
export function bytesToMegaBytes(bytes: number): string {
  const megaBytes = bytes / (FILE_SIZE_MULTIPLIER * FILE_SIZE_MULTIPLIER);
  return megaBytes.toFixed(2);
}

/**
 * Sort files by name
 *
 * @param files The array of file
 * @returns Sorted array of the files
 */
export function sortFiles(files: File[]): File[] {
  return files.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
}

// Contains the supported image file extensions
const IMAGE_FILE_TYPES = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "webp",
  "avif",
  "svg",
  "apng",
];

/**
 * Check if the provided file extension is an image file type.
 *
 * @param fileExtension - The file extension to check.
 * @returns boolean - true if the file is an image, false otherwise.
 */
export function isImageFileType(fileExtension: string): boolean {
  return IMAGE_FILE_TYPES.includes(fileExtension.toLowerCase());
}

/**
 * Extracts the file extension from a given file name.
 *
 * @param fileName - The full name of the file (including the extension).
 * @returns string - The file extension (e.g., 'jpg', 'png'), or an empty string if there is no extension.
 */
export function getFileExtension(fileName: string): string {
  const trimmedFileName = fileName.trim();

  if (
    trimmedFileName.startsWith(".") &&
    trimmedFileName.indexOf(".", 1) === -1
  ) {
    return "";
  }

  const fileNameParts = trimmedFileName.split(".");
  return fileNameParts.length > 1
    ? (fileNameParts.pop() ?? "").toLowerCase()
    : "";
}
