import { Team } from "@custom-types/teams-types";
import { CoreAPITypes } from "@stellar/api-logic";

/**
 * Gets a random number between min and max.
 *
 * @param {number} min The minimum number (inclusive)
 * @param {number} max The maximum number (inclusive)
 */
function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const mockedTeamNames = [
  {
    name: "TechTopia",
    id: "b3f421ff-38fe-44ff-bb3d-d4fa2355ed1e",
  },
  {
    name: "DataDynamix",
    id: "3f4da019-374f-4210-9d7b-3503910bb7b3",
  },
  {
    name: "CodeCrafters",
    id: "fc4ef321-79c4-463c-b1c9-1ab9580d5e0b",
  },
  {
    name: "ByteBloom",
    id: "8866628c-2739-4d5f-81b6-2144419d455e",
  },
  {
    name: "NovaSoft",
    id: "72c0f3e0-dbc0-4de8-bf92-9ae1d2a76092",
  },
  {
    name: "PowerTech Solutions",
    id: "4f30320d-0b54-493d-ab4e-01b7d762e03b",
  },
  {
    name: "Bright Byte",
    id: "aa997635-a96c-4809-ba11-a1aa832c3771",
  },
  {
    name: "Spark Software",
    id: "711d8a88-ea70-4fdf-b344-de1fbddd1e33",
  },
  {
    name: "CleverCoding",
    id: "5f14a8ca-4169-45ce-b65d-7d4a96cd4614",
  },
  {
    name: "CodeWave",
    id: "19b11019-bc92-4bac-a0c9-c4e17ee246d0",
  },
  {
    name: "Bright Blueprint",
    id: "fcca8ab3-54cc-4fbd-9d4e-b9f9fb74aedd",
  },
  {
    name: "Urban Nest",
    id: "e8803023-f665-4327-8840-360df2395eae",
  },
  {
    name: "Bold Structures",
    id: "4b0a8c6a-1b90-434d-b7ea-74b080b38d0b",
  },
  {
    name: "Visionary Architects",
    id: "10ddac08-08a1-4c33-b74f-b54330e49155",
  },
  {
    name: "Evergreen Realty",
    id: "8ee4e0da-8eb6-4c81-8f49-7538e8f9e371",
  },
  {
    name: "Integrity Construction",
    id: "efd485f4-86e1-427c-a9a5-2f7e98198971",
  },
  {
    name: "Sapphire Homes",
    id: "0d74ad8a-a933-4358-a629-84dd703eaab4",
  },
  {
    name: "Zenith Builders",
    id: "d7c5d217-4ff5-4a1f-88cd-c7f14ad4cf9b",
  },
  {
    name: "Harmony Estates",
    id: "bbd33a8d-2fce-46cb-baca-f03a743b9b75",
  },
  {
    name: "Envision Properties",
    id: "b05f2643-fe95-4c57-b317-b53a748e1ff2",
  },
  {
    name: "Solid Foundations",
    id: "25a11d84-68b1-4aaa-b2f4-a028cdca2c74",
  },
  {
    name: "Creative Builds",
    id: "961446a2-9597-45e4-8f70-3792d073626b",
  },
  {
    name: "Crafted Homes",
    id: "85a911ba-b013-4231-830d-46f1b8eed87c",
  },
  {
    name: "Urban Developments",
    id: "b6805bee-7a4c-4fc5-a221-c0d437616abc",
  },
  {
    name: "Prime Properties",
    id: "32ef16db-99d7-458c-9a93-8ed9e1541043",
  },
  {
    name: "Elite Construction",
    id: "9c89c1c9-7432-4e20-8d39-6fc5ddfe8a99",
  },
  {
    name: "Innovative Structures",
    id: "4726f148-7a0a-4d86-bece-850089b56016",
  },
  {
    name: "Precision Builders",
    id: "1631bdf9-1a07-4422-a372-dfab4d875abc",
  },
  {
    name: "Harmony Homes",
    id: "02991b3a-386b-4ebe-b073-f31bd5c42c4a",
  },
  {
    name: "Urban Upgrades",
    id: "703fe2a3-9837-4449-9e60-736305a8668f",
  },
  {
    name: "Vision Quest",
    id: "9b93a127-bf9b-4224-8c1a-4067b2d8749c",
  },
  {
    name: "Beam Basics",
    id: "eb893088-0a8b-462f-af48-d609d7ceac12",
  },
  {
    name: "Pulse Point",
    id: "6a5122b0-b44a-4830-9a4f-05bd43b2a47b",
  },
  {
    name: "Reflective Innovations",
    id: "6c3e8940-ec22-4087-81aa-cd6719503616",
  },
  {
    name: "Spectrum Search",
    id: "68e56153-21e8-492a-b5e5-f96a5ddcc93a",
  },
  {
    name: "DataDrive",
    id: "2aa3311f-9e1f-47ba-aed8-dd3603377bd9",
  },
  {
    name: "TechnoScan",
    id: "dc250f2d-bac6-4bb7-9b94-04276e7a4e42",
  },
  {
    name: "ClearView",
    id: "b8909bdd-e5a7-414e-b303-70b6c1af22c4",
  },
  {
    name: "Insightful Imaging",
    id: "482ae548-ddb2-4038-9737-3fb3163fbe0b",
  },
  {
    name: "Radiant Reconnaissance",
    id: "8823a6aa-59bf-4ccb-be0b-7d1954c853b9",
  },
  {
    name: "Design Dreams",
    id: "c66fdcc9-ec1d-4b57-a652-c3d0e3bcc587",
  },
  {
    name: "Blueprint Creations",
    id: "bc1a2f69-6b44-42c8-bf9d-6d978665e055",
  },
  {
    name: "Model Master",
    id: "4b543d3e-edbc-4cbb-a1d0-1f9b8600b360",
  },
  {
    name: "Visionary Designs",
    id: "19157901-825f-4929-a829-cc9fe358ec5c",
  },
  {
    name: "Pro Modeling",
    id: "1676e18e-70c5-4813-8f23-f1696246d850",
  },
  {
    name: "Crafted Creations",
    id: "56454508-5646-4f48-bbda-babbdd574783",
  },
  {
    name: "Drafting Genius",
    id: "fc779f3f-df19-4806-b66e-21013a15abe5",
  },
  {
    name: "Precision Designs",
    id: "807a6b3e-5972-4bd3-9caf-f9206068053a",
  },
  {
    name: "Model Magic",
    id: "820b52d2-bf04-4793-a8dd-2d4393e7d0fd",
  },
  {
    name: "Creative Drafting",
    id: "8308b0f6-b88d-4ac8-8ae0-a995b766a49c",
  },
  {
    name: "TeamTech",
    id: "a52712bc-7039-4d5e-b3b4-8198a707ddb6",
  },
  {
    name: "CodeCraft",
    id: "3a62df65-98d6-4de7-9476-46f12c54c892",
  },
  {
    name: "Innovate Solutions",
    id: "9599a2a1-8985-46aa-9541-47c5a8013dbd",
  },
  {
    name: "Junction Software",
    id: "b0ef4298-7931-4c1e-9e02-6e6d97ca9648",
  },
  {
    name: "Agile Allies",
    id: "ba949f6c-c6af-494d-8a55-585ea6759775",
  },
  {
    name: "Smart Squad",
    id: "e9f0f173-f36e-46d8-8af8-541c47e493e5",
  },
  {
    name: "Tech Titans",
    id: "218cd1ce-cbc6-4e2b-b277-86c6e3742934",
  },
  {
    name: "Visionary Developers",
    id: "791c3a29-147b-4ef2-8059-46ad96f47c65",
  },
  {
    name: "Swift Builders",
    id: "044f21f0-a9f2-4dc8-ac62-c138f2771c18",
  },
  {
    name: "Software Creators",
    id: "74bbad2d-bcdb-45f7-a316-cfb7b60aa534",
  },
];

const mockedUserNames = [
  {
    name: "Wilma Watkins",
    id: "4164617d-77b3-4825-8fa8-3d703f63b5eb",
  },
  {
    name: "Lewis Shepherd",
    id: "b9dd9e57-1aeb-44d9-bf31-031c09b76453",
  },
  {
    name: "Chester Valencia",
    id: "3f2a8f1a-f500-4e0e-99ad-7729f009ef3a",
  },
  {
    name: "Elena Long",
    id: "288eec07-dc10-4f31-bb99-3a8eb4566fa1",
  },
  {
    name: "Billie Watts",
    id: "148f4e42-f8e7-4b0f-b269-6233cec4cfff",
  },
  {
    name: "Polly Tuttle",
    id: "cbc78910-8c39-4567-b2bc-002b1945463f",
  },
  {
    name: "Marlene Randall",
    id: "a9cf57fb-4913-43b3-bf85-3ca0a5efe1be",
  },
  {
    name: "Damon Huber",
    id: "c0bc0908-0153-4719-88c7-b46bbdc9a96e",
  },
  {
    name: "Bert Gibbons",
    id: "c1c45d1d-6354-4c89-a61b-bff1b6139ea8",
  },
  {
    name: "Joann Daily",
    id: "3084b25c-ba5a-4a2e-bffb-a7b2033d7682",
  },
  {
    name: "Christy Marks",
    id: "5fee40cf-14e9-4f67-b0c2-fb301211961e",
  },
  {
    name: "Molly Cobb",
    id: "1d31234c-f045-42b0-8282-dabe330d560f",
  },
  {
    name: "Angelica Myers",
    id: "8a573812-82cc-4a6b-ae5b-dca47c5fb467",
  },
  {
    name: "Roy Baker",
    id: "cda8d571-04f2-4e9b-af25-ffe0bf000059",
  },
  {
    name: "Byron Morton",
    id: "8fef6ae9-12bb-4862-806b-c274239a3478",
  },
  {
    name: "Mario Barr",
    id: "539de364-8bec-4bd5-a2c0-61542160f964",
  },
  {
    name: "Saul Bean",
    id: "6d18d1f9-3adb-4408-9415-abef56e37007",
  },
  {
    name: "Naomi Wilcox",
    id: "5f9438fb-0ef0-4161-9237-60b9cf5b7d33",
  },
  {
    name: "Jason Newman",
    id: "46fd6f26-bcc8-48d8-b931-e97d39ddf755",
  },
  {
    name: "Karen Jefferson",
    id: "b93d92b8-e9ed-4fb5-ac6f-da51d0a957ca",
  },
  {
    name: "Martha Elder",
    id: "ececaf42-a76e-46a1-b6e1-a99ca95625dc",
  },
  {
    name: "Felix Booker",
    id: "88fdb56c-5ae7-4233-aba4-df3ef430865a",
  },
  {
    name: "Angelo Haas",
    id: "e401c63f-c70d-4077-8d04-8e145e1fd527",
  },
  {
    name: "Tom Quick",
    id: "ef642f93-fdc0-4d26-9827-844d01a7cf08",
  },
  {
    name: "Julie Bright",
    id: "a43af037-39eb-42d3-9334-d52399d2fef2",
  },
  {
    name: "Paulette Lawrence",
    id: "ac372dec-4252-44cd-8712-4d2562f719a5",
  },
  {
    name: "Mitch Hughes",
    id: "9a95f93f-e112-429f-8899-a0ebee661c09",
  },
  {
    name: "Beatriz Fitch",
    id: "176c5a33-beed-4cc0-a8a9-e496eb5f708b",
  },
  {
    name: "Mathew Gray",
    id: "b9cb033a-5e90-4a27-9d1f-877703106cf8",
  },
  {
    name: "Will Riggs",
    id: "7c8603a6-ca80-4f10-a48e-7af13daf67e9",
  },
  {
    name: "Elmer Rocha",
    id: "23f65be7-4941-49b8-8bbb-5b44d2c30a74",
  },
  {
    name: "Graciela Cannon",
    id: "54d11513-f17a-4539-b706-f5f32e4db32a",
  },
  {
    name: "Tracey Fernandez",
    id: "f0ce1cb9-9ec9-4f74-b461-5007920966df",
  },
  {
    name: "Louis Merritt",
    id: "d6b6584e-1660-44c3-b7ae-386a1e6062e9",
  },
  {
    name: "Lynda Rankin",
    id: "716db466-b870-492b-9225-6413da2ec21b",
  },
  {
    name: "Lucille Reed",
    id: "dcbdab8c-2eaa-44f1-aa9e-1fed850ecd93",
  },
  {
    name: "Clarence Ward",
    id: "0413d79d-f2cd-4a5a-b323-fc1b10b76ec7",
  },
  {
    name: "Lesley Conrad",
    id: "ece0dc9e-0b37-4f3a-8978-d80658a6b3a5",
  },
  {
    name: "April Greenwood",
    id: "af59a731-f5ce-4295-9046-09ff013fa9c4",
  },
  {
    name: "Caitlin Rutherford",
    id: "de10f8a1-4591-4fff-ade9-58c23e1f4a6c",
  },
  {
    name: "Amber Mahoney",
    id: "9a1fa0ad-b14f-4ed0-87b0-44b9778c2b77",
  },
  {
    name: "Gustavo Paul",
    id: "7732c7e7-dfed-4824-96fb-90a6bc1e2818",
  },
  {
    name: "Dwight Harden",
    id: "3f72b170-6626-4e3f-a431-0ad075c2dfe7",
  },
  {
    name: "Arlene Kirby",
    id: "b166efd9-4434-4e8c-b922-9177780a2e13",
  },
  {
    name: "Milton McGee",
    id: "4b577103-dd2d-4ad4-bdb9-15ecd8ac9e80",
  },
  {
    name: "Pamela Abbott",
    id: "ad6f4a87-7df4-42a0-8f80-470e3cc33411",
  },
  {
    name: "Young Henson",
    id: "1be51fad-52bf-408b-947e-827527c5ba58",
  },
  {
    name: "Roland Burns",
    id: "617f733f-f5f6-46bd-9300-a1c740d60343",
  },
  {
    name: "Katrina Wallace",
    id: "cfe9372b-c0af-4227-89c2-ccc5ece4d561",
  },
  {
    name: "Daniel Murry",
    id: "c4d13e4a-9f2c-4f10-8e0d-58931278dc89",
  },
];

/**
 * Gets a random number of users from the mocked users list.
 *
 * @returns {string[]} The list of random users
 */
export function getRandomUsers(): Array<{ name: string; id: string }> {
  const numUsers = getRandomNumber(0, mockedUserNames.length - 1);
  return mockedUserNames.sort(() => Math.random()).slice(0, numUsers);
}

const startFakeDate = new Date("2022-01-01").getTime();
const endFakeDate = new Date().getTime();

export const mockedTeams: Team[] = mockedTeamNames.map((mockedTeam) => {
  // Only show description 50% of the time
  const description =
    getRandomNumber(1, 2) === 1
      ? `Description for Group ${mockedTeam.name}`
      : undefined;

  const randomDateTime = getRandomNumber(startFakeDate, endFakeDate);
  const randomDate = new Date(randomDateTime).toISOString();

  return {
    id: mockedTeam.id,
    name: mockedTeam.name,
    description,
    createdAt: randomDate,
    sampleMembers: getRandomUsers().map((mockedUser) => {
      const id = mockedUser.id;
      const [firstName, lastName] = mockedUser.name.split(" ");
      return {
        userResponse: {
          id,
          identity: id,
          firstName,
          lastName,
          email: `${firstName}.${lastName}@faro.com`.toLowerCase(),
          role: CoreAPITypes.EUserCompanyRole.member,
        },
        createdAt: "2023-09-17T12:00:00Z",
      };
    }),
  };
});
