import { MemberTableSubject } from "@components/common/faro-table/faro-table-types";
import { RemoveSingleMemberFromEntity } from "@components/remove-single-member-from-entity";
import { BaseMemberProps } from "@custom-types/member-types";
import { SdbProject } from "@custom-types/project-types";
import {
  isGroupDetailsMember,
  isProjectMember,
} from "@custom-types/type-guards";
import {
  APITypes,
  CoreAPITypes,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { getCompanyByIdSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useHasUserValidRoleSnapshotLevel } from "@hooks/access-control/use-has-user-valid-role-snapshot-level";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { useMemo } from "react";
import { TeamMemberActions } from "@pages/members/teams/team-member-actions";
import { Team } from "@custom-types/teams-types";

interface Props extends BaseCompanyIdProps, BaseMemberProps {
  /** The selected project if there is one */
  selectedProject?: SdbProject | null;

  /** Recognizes on which subject this component is being used */
  subjectType: MemberTableSubject;

  /** The selected group if there is one */
  group?: SphereDashboardAPITypes.IGroupDetails | null;

  /** The selected snapshot if there is one */
  snapshot?: CoreAPITypes.IProjectSnapshot;

  /** The selected team if there is one */
  team?: Team;
}

/** Content to show for options member column */
export function OptionsMemberColumn({
  companyId,
  selectedProject,
  subjectType,
  member,
  group,
  snapshot,
  team,
}: Props): JSX.Element | null {
  const selectedSdbCompany = useAppSelector(getCompanyByIdSelector(companyId));
  const { isSnapshotAdmin } = useHasUserValidRoleSnapshotLevel({
    selectedSnapshot: snapshot ?? null,
  });
  const { canDeleteMemberFromProject } = useHasUserValidRoleProjectLevel({
    selectedProject,
  });

  const shouldHideTheProjectOptions = useMemo(
    () =>
      selectedProject &&
      selectedProject.archivingState === APITypes.ArchivingState.ARCHIVED,
    [selectedProject]
  );

  // If there is a selected project then show dialog to remove member from project.
  if (selectedProject && subjectType === "project" && isProjectMember(member)) {
    if (shouldHideTheProjectOptions || !canDeleteMemberFromProject) {
      return null;
    }

    return (
      <RemoveSingleMemberFromEntity
        companyId={companyId}
        member={member}
        entity={selectedProject}
        entityName="project"
      />
    );
  }

  // If there is a selected group then show dialog to remove member from group.
  if (group && subjectType === "group" && isGroupDetailsMember(member)) {
    return (
      <RemoveSingleMemberFromEntity
        companyId={companyId}
        member={member}
        entity={group}
        entityName="group"
      />
    );
  }

  /**
   * If the context type is `snapshot` only show the remove member button if:
   * - The member does not have the project role `RESOURCE_OWNER` and
   * - The current user has the `owner` snapshot role
   */
  if (
    snapshot &&
    subjectType === "snapshot" &&
    isProjectMember(member) &&
    member.role !== CoreAPITypes.EUserProjectRole.owner &&
    isSnapshotAdmin
  ) {
    return (
      <RemoveSingleMemberFromEntity
        companyId={companyId}
        member={member}
        entity={snapshot}
        entityName="snapshot"
      />
    );
  }

  // If it is not a project member then show dialog to remove member from workspace.
  if (
    subjectType === "workspace" &&
    selectedSdbCompany &&
    !isProjectMember(member)
  ) {
    return (
      <RemoveSingleMemberFromEntity
        companyId={companyId}
        member={member}
        entity={selectedSdbCompany}
        entityName="workspace"
      />
    );
  }

  // If the options column is using for team members table.
  if (team && subjectType === "team" && selectedSdbCompany) {
    return (
      <TeamMemberActions companyId={companyId} member={member} team={team} />
    );
  }

  // If didn't match the criteria then return empty string.
  return null;
}
