import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroMenu, FaroMenuItem } from "@faro-lotv/flat-ui";
import { useMemo, useRef, useState } from "react";
import VerticalDotsIcon from "@assets/icons/new/vertical-dots_28px.svg?react";
import { useMembersUtils } from "@hooks/use-member-utils";
import { MemberTypes } from "@custom-types/member-types";
import { Typography } from "@mui/material";
import { UserDisplay } from "@components/common/user-display";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  TableType,
  TeamMemberEvents,
} from "@utils/track-event/track-event-list";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useCoreApiClient } from "@api/use-core-api-client";
import { removeMembersFromTeam } from "@store/teams/teams-slice-thunk";
import { fetchingTeamsFlagsSelector } from "@store/teams/teams-selector";
import { RemoveMemberSuccessDescription } from "@components/remove-member-success-description";
import { useToast } from "@hooks/use-toast";
import { BaseTeamProps } from "@custom-types/teams-types";

interface Props extends BaseCompanyIdProps, BaseTeamProps {
  /** The member of a team */
  member: MemberTypes;
}

/** Renders actions dropdown for team members */
export function TeamMemberActions({
  member,
  companyId,
  team,
}: Props): JSX.Element {
  const { openProfilePage } = useMembersUtils();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { isRemovingTeamMember } = useAppSelector(fetchingTeamsFlagsSelector);
  const { showToast } = useToast();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState<boolean>(false);

  /** Text to show when the member was removed */
  const successDescription = useMemo(() => {
    return (
      <RemoveMemberSuccessDescription
        member={member}
        subjectType="team"
        entityName={team.name}
      />
    );
  }, [member, team.name]);

  function onOpenProfile(): void {
    openProfilePage({
      userIdentity: member.identity,
      source: "team members table",
    });
  }

  async function removeMember(): Promise<void> {
    trackEvent({
      name: TeamMemberEvents.removeMember,
      props: { dataType: TableType.teamMembers },
    });

    try {
      await dispatch(
        removeMembersFromTeam({
          coreApiClient,
          companyId,
          teamId: team.id,
          memberIds: [member.identity],
        })
      ).unwrap();

      showToast({
        message: "Member removed from team",
        description: successDescription,
        type: "success",
      });
    } finally {
      setIsRemoveDialogOpen(false);
    }
  }

  return (
    <>
      <FaroIconButton
        aria-label="more"
        aria-haspopup="true"
        component={VerticalDotsIcon}
        onClick={() => setIsMenuOpen(true)}
        iconButtonProps={{ ref: anchorRef }}
      />
      <FaroMenu
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        onClose={() => setIsMenuOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FaroMenuItem label="Profile" onClick={onOpenProfile} />
        <FaroMenuItem
          label="Remove from group"
          onClick={() => setIsRemoveDialogOpen(true)}
        />
      </FaroMenu>

      {/* Dialog with the details of the member to be removed */}
      <FaroDialog
        title="Remove from team"
        confirmText="Remove"
        open={isRemoveDialogOpen}
        onConfirm={removeMember}
        onClose={() => setIsRemoveDialogOpen(false)}
        isConfirmLoading={isRemovingTeamMember}
      >
        <Typography
          sx={{
            fontSize: "14px",
            marginTop: "0px",
            marginBottom: SPACE_ELEMENTS_OF_MODAL,
          }}
        >
          When removing member from team they will no longer be able to access
          this team. You can always invite this member again.
        </Typography>

        <UserDisplay member={member} />
      </FaroDialog>
    </>
  );
}
