import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageInfoBar } from "@components/common/page-info-bar";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { TeamTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { BaseTeamProps } from "@custom-types/teams-types";
import { useMemo } from "react";
import { InviteMemberToTeam } from "@pages/members/teams/invite-member-to-team";
import { capitalizeFirstLetter } from "@utils/string-utils";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";

interface Props extends Partial<BaseTeamProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Info bar for the team overview tabs
 */
export function TeamDetailsInfoBar({
  team,
  isLoading = false,
}: Props): JSX.Element {
  const { teamTabs } = useAppParams();
  const { canInviteMembersToTeam } = useHasUserValidRoleCompanyLevel();

  const shouldShowActionButtons = useMemo(() => {
    if (!team || isLoading) {
      return false;
    }

    return teamTabs === TeamTabs.members && canInviteMembersToTeam;
  }, [canInviteMembersToTeam, isLoading, team, teamTabs]);

  if (!team || isLoading) {
    // Early exit with a loading skeleton if the team is not yet loaded
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <PageInfoBar
      items={[
        {
          columnSize: 3,
          content: (
            <FaroTextField
              label={`${capitalizeFirstLetter(TEAM_DISPLAY_NAME)} name`}
              initialValue={team.name}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          columnSize: 6,
          content: (
            <FaroTextField
              label="Members"
              initialValue={team.sampleMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={
        // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to keep the position of items constant
        shouldShowActionButtons ? <InviteMemberToTeam team={team} /> : <></>
      }
    />
  );
}
