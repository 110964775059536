import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TeamsHeaders } from "@components/table/teams/teams-table-types";
import { Team } from "@custom-types/teams-types";
import { FormatDate } from "@hooks/use-date-time";
import { TeamName } from "@components/table/teams/team-name";
import { DateTime } from "luxon";
import { TeamsMembersColumn } from "@components/table/teams/teams-members-column";
import { TeamsTableAction } from "@components/table/teams/teams-table-action";

interface Props {
  // TODO: Extract it in separate file or access in in the component: https://faro01.atlassian.net/browse/ST-1348
  options: {
    /** Whether the current screen is large or larger */
    isScreenLgOrLarger: boolean;

    /** Whether the current screen is medium */
    isMedium: boolean;
  };

  /** The function to format date. Needs to be passed since getMembersColumns is not a hook */
  formatDate: FormatDate;
}

/** Minimum column width in px */
const COLS_MIN_WIDTH_IN_PX = 100;

/** Return all the possible columns for Teams table */
export function getTeamsColumns({
  options,
  formatDate,
}: Props): Record<TeamsHeaders, GridColDef> {
  const flexSetting = options.isScreenLgOrLarger ? 1 : undefined;

  return {
    [TeamsHeaders.name]: {
      field: TeamsHeaders.name,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: 450,
      renderCell: (params: GridRenderCellParams<{ entity: Team }>) => {
        return <TeamName team={params.row.entity} />;
      },
    },
    [TeamsHeaders.users]: {
      field: TeamsHeaders.users,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: 450,
      renderCell: (params: GridRenderCellParams<{ entity: Team }>) => {
        return <TeamsMembersColumn members={params.row.entity.sampleMembers} />;
      },
    },

    [TeamsHeaders.creationDate]: {
      field: TeamsHeaders.creationDate,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: 400,
      renderCell: (params: GridRenderCellParams<{ entity: Team }>) => {
        return (
          <var>
            {formatDate(params.row.entity.createdAt, DateTime.DATETIME_MED)}
          </var>
        );
      },
    },

    [TeamsHeaders.options]: {
      field: TeamsHeaders.options,
      type: "actions",
      align: "right",
      flex: 0.5,
      maxWidth: 150,
      renderCell: (params: GridRenderCellParams<{ entity: Team }>) => {
        return <TeamsTableAction team={params.row.entity} />;
      },
    },
  };
}
